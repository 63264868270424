/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import logo from "../assets/logo-lokap.png";
import { motion } from "framer-motion";

function Navbar({ navList, showDemo }) {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsSticky(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header
      className={`container max-w-[1200px] z-30 px-4 mx-auto mt-12 ${
        isSticky ? "sticky top-1 md:top-4 z-10" : ""
      }`}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <nav className="p-2 flex flex-col items-center gap-1 md:grid md:grid-cols-2 bg-[#F0F0F0] bg-opacity-50 rounded-xl">
        <div className="flex items-center justify-between w-full">
          <a href="/">
            <img src={logo} alt="logo" className="h-10" />
          </a>

          {/* Hamburger Menu Icon */}
          <div
            className="md:hidden cursor-pointer"
            onClick={() => {
              toggleMobileMenu();
              setIsSticky(!isSticky);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </div>
        </div>

        {/* Mobile Menu */}
        {false && (
          <ul className="md:hidden flex flex-col gap-2 pb-3 items-center">
            {navList.map((navItem, index) => (
              <motion.li 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 + index * 0.15 }}
                key={navItem.text}>
                <a
                  href={navItem.url}
                  className="block py-2 px-4 hover:bg-gray-200"
                  onClick={toggleMobileMenu}
                >
                  {navItem.text}
                </a>
              </motion.li>
            ))}
            <li>
            {false && (
            <motion.a
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              href="https://app.lokap.co/cjEYrG3qxhS1"
              target="_blank"
              className="px-4 text-center py-2 w-1/2 max-w-[100px] rounded-xl bg-orange-500 text-white font-semibold"
            >
              Demo
            </motion.a>
          )}
            </li>
          </ul>
        )}

        <div className="flex flex-col justify-between items-center md:flex-row gap-2">
          {/* Desktop Menu */}
          <ul className="hidden md:flex gap-3 items-center text-md md:text-lg">
            {navList.map((navItem) => (
              <li key={navItem.text}>
                <a href={navItem.url}>{navItem.text}</a>
              </li>
            ))}
          </ul>
          {showDemo && (
            <a
              href="product/carte-ultra"
              // target="_blank"
              className="hidden md:block px-4 text-center py-2 rounded-xl bg-orange-500 text-white font-semibold"
            >
              Commandez
            </a>
          )}
        </div>
      </nav>
    </header>
  );
}

Navbar.propTypes = {
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  showDemo: PropTypes.bool,
};

export default Navbar;
