import React from 'react'
import CountdownTimer from './countdown'

export default function AnnouncementBar() {
  return (
    <div className='announcement-bar'>
      <CountdownTimer/>
    </div>
  )
}
