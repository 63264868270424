/* eslint-disable */
import React from "react";

function SvgQrCode({qrCodeColor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      x="0"
      y="0"
      fill={qrCodeColor}
      version="1.1"
      viewBox="0 0 1160 1160"
      xmlSpace="preserve"
    >
      <path fill="rgba(255,255,255, 0)" d="M0 0H1160V1160H0z"></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) translate(320) scale(.08)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) translate(480) scale(.08)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) translate(560) scale(.08)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) translate(640) scale(.08)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 40)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 40)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 40)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 80)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 80)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 80)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 80)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 80)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 120)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 120)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 120)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 120)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 160)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 160)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 160)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 160)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 160)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 200)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 200)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 240)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 240)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 240)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 240)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 240)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 280)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 280)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 280)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 280)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 160 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 200 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 240 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 280 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 320)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 160 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 200 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 280 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 360)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 160 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 240 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 400)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 80 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 160 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 440)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 160 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 240 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 480)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 80 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 200 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 520)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 40 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 160 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 240 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 560)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 80 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 600)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 0 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 120 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 240 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 280 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 640)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 680)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 680)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 680)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 680)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 680)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 680)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 560 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 720)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 760)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 600 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 800)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 720 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 840)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 880)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 760 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 800 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 880 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 920 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 920)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 320 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 360 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 400 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 440 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 480 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 520 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 640 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 680 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 840 960)"
      ></path>
      <path
        d="M250 0c138.077 0 250 111.93 250 250 0 138.077-111.923 250-250 250C111.93 500 0 388.077 0 250 0 111.93 111.93 0 250 0z"
        transform="translate(80 80) matrix(.08 0 0 .08 960 960)"
      ></path>
      <path
        fill="none"
        d="M85 66.221V33.75C85 23.411 76.414 15 65.859 15H34.14C23.586 15 15 23.411 15 33.75v51.246l50.855-.016C76.414 84.98 85 76.564 85 66.221z"
        transform="translate(80 80) scale(2.8) matrix(-1 0 0 1 100 0)"
      ></path>
      <path
        d="M100 66.221V33.75C100 15.141 84.68 0 65.859 0H34.14C15.32 0 0 15.141 0 33.75V100l65.859-.02C84.68 99.98 100 84.84 100 66.221zm-15 0c0 10.344-8.586 18.76-19.145 18.76L15 84.996V33.75C15 23.411 23.586 15 34.14 15h31.719C76.414 15 85 23.411 85 33.75v32.471z"
        transform="translate(80 80) scale(2.8) matrix(-1 0 0 1 100 0)"
      ></path>
      <path
        fill="none"
        d="M85 66.221V33.75C85 23.411 76.414 15 65.859 15H34.14C23.586 15 15 23.411 15 33.75v51.246l50.855-.016C76.414 84.98 85 76.564 85 66.221z"
        transform="translate(80 80) translate(720) scale(2.8)"
      ></path>
      <path
        d="M100 66.221V33.75C100 15.141 84.68 0 65.859 0H34.14C15.32 0 0 15.141 0 33.75V100l65.859-.02C84.68 99.98 100 84.84 100 66.221zm-15 0c0 10.344-8.586 18.76-19.145 18.76L15 84.996V33.75C15 23.411 23.586 15 34.14 15h31.719C76.414 15 85 23.411 85 33.75v32.471z"
        transform="translate(80 80) translate(720) scale(2.8)"
      ></path>
      <path
        fill="none"
        d="M85 66.221V33.75C85 23.411 76.414 15 65.859 15H34.14C23.586 15 15 23.411 15 33.75v51.246l50.855-.016C76.414 84.98 85 76.564 85 66.221z"
        transform="translate(80 80) matrix(2.8 0 0 2.8 0 720) rotate(180 50 50)"
      ></path>
      <path
        d="M100 66.221V33.75C100 15.141 84.68 0 65.859 0H34.14C15.32 0 0 15.141 0 33.75V100l65.859-.02C84.68 99.98 100 84.84 100 66.221zm-15 0c0 10.344-8.586 18.76-19.145 18.76L15 84.996V33.75C15 23.411 23.586 15 34.14 15h31.719C76.414 15 85 23.411 85 33.75v32.471z"
        transform="translate(80 80) matrix(2.8 0 0 2.8 0 720) rotate(180 50 50)"
      ></path>
      <g>
        <g transform="translate(80 80) matrix(1.2 0 0 1.2 80 80)">
          <path d="M27.351 100C12.261 100-.014 87.968-.014 73.192V26.794c0-4.616 1.2-8.96 3.301-12.761a27.062 27.062 0 018.36-9.174A27.606 27.606 0 0127.336 0h45.327c15.076 0 27.351 12.018 27.351 26.793v46.398c0 14.775-12.274 26.808-27.351 26.808H27.351z"></path>
        </g>
      </g>
      <g>
        <g transform="translate(80 80) matrix(1.2 0 0 1.2 800 80)">
          <path d="M27.351 100C12.261 100-.014 87.968-.014 73.192V26.794c0-4.616 1.2-8.96 3.301-12.761a27.062 27.062 0 018.36-9.174A27.606 27.606 0 0127.336 0h45.327c15.076 0 27.351 12.018 27.351 26.793v46.398c0 14.775-12.274 26.808-27.351 26.808H27.351z"></path>
        </g>
      </g>
      <g>
        <g transform="translate(80 80) matrix(1.2 0 0 1.2 80 800)">
          <path d="M27.351 100C12.261 100-.014 87.968-.014 73.192V26.794c0-4.616 1.2-8.96 3.301-12.761a27.062 27.062 0 018.36-9.174A27.606 27.606 0 0127.336 0h45.327c15.076 0 27.351 12.018 27.351 26.793v46.398c0 14.775-12.274 26.808-27.351 26.808H27.351z"></path>
        </g>
      </g>
    </svg>
  );
}

export default SvgQrCode;