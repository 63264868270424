import React, { useState } from "react";
import CircularProgressLoader from "./Loader";
import axios from "axios";

const PromoCodeInput = ({onSuccess}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeC, setPromoCodeC] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [promoObject, setPromoObject] = useState();

  const handleCheck = async () => {
    if(promoCode === "") {
        return;
    }
    setIsLoading(true);
    setIsError(false);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
        url: "https://api2.lokap.co/api/orders/check-promo-code",
    //   url: "http://127.0.0.1:4000/api/orders/check-promo-code",
      headers: {
        "x-xrf-token":
          "tNV7R4wsuuCx6c2oOR2sMhaGgZ98HkI9iRhMzhrwqfbmgbHjJSG82XXokWsk4980ZoD9sABTxMDTutOVYzV85oPElqP1TiBgWDLoV7rSZMlkZkoYZE5u4ue2usNX5AtALE45vI16JSwzHtzeDCKsjCmKFzsoyBTDGE1ZsGOPvFsfrI6lZUSWQUxPKrCc9xGLOejxB22G",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        promo_code: promoCode,
      }),
    };

    try {
      const response = await axios.request(config);
      setIsLoading(false);
      localStorage.setItem("promo_code", response.data.code);
      localStorage.setItem("promo_value", response.data.value);
      setPromoObject(response.data);
      setPromoCodeC(promoCode);
      setIsSuccess(true);
      setIsOpen(false);
      onSuccess(response.data);
    } catch (error) {
      setPromoCodeC(promoCode);
      setIsError(true);
      setIsLoading(false);
      console.log(error);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {isSuccess ? (
        <div
          className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          {/* <strong className="font-bold"></strong> */}
          <span className="block sm:inline">
            {`Votre code promo « ${promoCodeC} »  été appliqué, Vous bénéficiez maintenant d'une réduction de -${promoObject.value * 100}% sur votre commande.`}
          </span>
        </div>
      ) : (
        ""
      )}
      {!isOpen && !isSuccess  ? (
        <a className="text-blue-500 underline" onClick={toggleOpen}>
          Ajouter un code promo
        </a>
      ) : (
        ""
      )}
      {isOpen ? (
        <>
          <div className="flex items-center mt-4" >
            <input
              type="text"
              onChange={(e) => setPromoCode(e.target.value)}
              placeholder="Code promo"
              className="border rounded-l px-2 py-2"
            />
            <div
              className="bg-stone-800 text-white px-4 py-2 rounded-r"
              onClick={handleCheck}
            >
              {isLoading ? <CircularProgressLoader /> : "Appliquer"}
            </div>
          </div>
          {isError ? (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4"
              role="alert"
            >
              {/* <strong className="font-bold"></strong> */}
              <span className="block sm:inline">
                {`  Le code promo « ${promoCodeC} » n’existe pas !`}
              </span>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PromoCodeInput;
