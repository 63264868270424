/* eslint-disable */
import React from "react";
import FAQElements from "./FAQElements";
import ColoredTxt from "./ColoredTxt";

function FAQ() {
  return (
    <section
      id="faq"
      style={{ scrollMarginTop: "10rem" }}
      className="container px-3 mx-auto max-w-[900px] my-44 flex flex-col gap-12"
    >
      <div className="w-full text-center">
        <ColoredTxt text="FAQ" />
      </div>
      <div>
        <FAQElements
          question="Comment la carte LOKAP fonctionne ?"
          answer="La carte est équipe d'une puce électronique qui utilise la technologie NFC. <br/>Cette puce permet d'envoyer des informations sans contact aux smartphones. <br/>Quand vous apposerez la carte sur le smartphone d'un destinataire (en haut sur un iPhone, au dos sur un android), un lien s'ouvrira sur le téléphone cible et fera apparaitre votre fiche contacte. <br/>Pour les smartphones plus anciens, un QR est également disponible au dos de la carte."
        />
        <FAQElements
          question="Puis-je mettre à jour ma carte LOKAP ?"
          answer="<b>OUI !</b> C'est un des grands avantages de la carte LOKAP: vos informations présentes sur votre fiche sont entièrement actualisables en temps réels via un portail en ligne d'administration !"
        />

        <FAQElements
          question="Faut-il installer une application ?"
          answer="Non. Aucune application n'est nécessaire. <br/>Il suffit simplement d'approcher la carte du smartphone. La technologie NFC est celle utilisée par Apple et Android. <br/>Nous utilisons cette fonctionnalité native des smartphones d'aujourd'hui. "
        />
        <FAQElements
          question="Quels sont les téléphones compatibles ?"
          answer="<b>APPLE :</b> <br/>
          100% fonctionnel : iPhone XS, XR, 11, 12 et 13
          <br/>
          Activez le NFC dans le centre de contrôle avant d'approcher la carte LOKAP : iPhone 7,8 et X (IOS 14)
          <br/><br/>
          <b>SAMSUNG :</b><br/>
          Tous les téléphones Samsung depuis 2015 sont compatibles :
          <br/>
          Galaxy S5, S6, S7, S8, S9, S10, S20<br/>
          Galaxy Note 8, 9, 10<br/>
          Galaxy A, modèles 2016 : A3, A5<br/>
          Galaxy A, modèles 2017 : A3, A5<br/>
          Galaxy A, modèles 2018 : A6, A7, A8, A9<br/>
          Galaxy A, modèles 2019 : A20e, A40, A50, A70<br/>
          Galaxy J, modèles 2018 : J3, J4+; J5, J6
          <br/><br/>
          <b>HTC :</b> <br/>
          Tous les téléphones HTC depuis 2015 sont compatibles :
          <br/>
          U19e, U12+, U12 Life<br/>
          Desire 12, Desire 12s (modèle 2017 non compatible)<br/>
          U11, Life, +<br/>
          Exodus 1
          <br/><br/>
          <b>GOOGLE :</b><br/>
          Pixel, Pixel 2, 3, 4 et 5<br/>
          Nexus 5, 6
          <br/><br/>
          <b>Huawei :</b><br/>
          P Smart 2021<br/>
          P10, P20 et P30
          <br/><br/>
          <b>OnePlus :</b><br/>
          Modèle 3, 5, 6, 7 et 8<br/>
          One
          <br/><br/>
          <b>LG :</b><br/>
          Q9/ One, Velvet<br/>
          One<br/>
          G8, G8s ThinQ<br/>
          G7, G7 ThinQ<br/>
          V50, V40<br/>
          V30, V35"
        />
        <FAQElements
          question="Faites-vous des tarifs pour les entreprises ?"
          answer="Tout à fait ! Nous appliquons des tarifs personnalisés en fonction de la quantité de carte commandées pour vos collaborateurs.
          Pour faire un devis pour votre entreprise, cliquez-ici. "
        />
      </div>
    </section>
  );
}

export default FAQ;
