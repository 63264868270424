/* eslint-disable */
import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import PropTypes from 'prop-types';

function FAQElements({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-gray-100 mb-4 rounded-2xl shadow-sm">
      <button
        type="button"
        className="flex justify-between items-center w-full py-4 px-6 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{question}</span>
        <span className="transform transition-transform duration-300">
          {isOpen ? <HiChevronUp /> : <HiChevronDown />}
        </span>
      </button>
      <Transition
        show={isOpen}
        enter="transition duration-200 ease-out"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-full"
        leave="transition duration-200 ease-in"
        leaveFrom="opacity-100 max-h-full"
        leaveTo="opacity-0 max-h-0"
      >
        <div className="px-6 py-4 text-gray-700" dangerouslySetInnerHTML={{ __html: answer }} />
      </Transition>
    </div>
  );
}

FAQElements.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FAQElements;
