import React, { useState } from "react";
import productsData from "../helpers/ConstsData";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgressLoader from "./Loader";
const UpsellModal = ({ isOpen, closeModal }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([
    { name: productsData[3].name, qte: 0, price: 49 },
    { name: productsData[4].name, qte: 0, price: 99 },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const incrementQuantity = (index) => {
    console.log("incrementing..");
    const updatedProducts = [...products];
    updatedProducts[index].qte += 1;
    setProducts(updatedProducts);
  };

  const decrementQuantity = (index) => {
    console.log("decrementing..");
    const updatedProducts = [...products];
    if (updatedProducts[index].qte > 0) {
      updatedProducts[index].qte -= 1;
      setProducts(updatedProducts);
    }
  };

  const handleUpsell = () => {
    setIsLoading(true);
    let selectedProducts = products.filter((prod) => prod.qte > 0);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api2.lokap.co/api/orders/upsell",
    //   url: "http://127.0.0.1:4000/api/orders/upsell",
      headers: {
        "x-xrf-token":
          "tNV7R4wsuuCx6c2oOR2sMhaGgZ98HkI9iRhMzhrwqfbmgbHjJSG82XXokWsk4980ZoD9sABTxMDTutOVYzV85oPElqP1TiBgWDLoV7rSZMlkZkoYZE5u4ue2usNX5AtALE45vI16JSwzHtzeDCKsjCmKFzsoyBTDGE1ZsGOPvFsfrI6lZUSWQUxPKrCc9xGLOejxB22G",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        order_id: localStorage.getItem("ordereId"),
        products: selectedProducts,
      }),
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsLoading(false);
        // fbq('track', 'Lead')
        navigate("/thankyou-page");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? "block" : "hidden"}`}>
      <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <div className="text-lg font-semibold mb-2">Découvertes à -50% !</div>
          <div className="text-sm text-gray-600 mb-4">
            Clients qui ont acheté ce produit ont aussi acheté
          </div>

          <div className="grid grid-cols-2 gap-4">
            {products.map((product, index) => (
              <div key={index} className="">
                <div className=" rounded p-2 relative">
                  <img
                    src={productsData[3 + index].images[0]}
                    alt="Product"
                    className="w-full h-32 object-cover mb-2"
                  />
                  <button
                    className="absolute bottom-0 right-0 -bottom-8 -right-7 bg-blue-500 text-white px-2 py-1 rounded bg-teal-400 rounded-full h-30 w-30 flex justify-center items-center square-30 z-1k"
                    onClick={() => incrementQuantity(index)}
                  >
                    +
                  </button>
                  {product.qte > 0 ? (
                    <button
                      className="absolute bottom-0 left-0 -bottom-8 -left-7 bg-blue-500 text-white px-2 py-1 rounded bg-teal-400 rounded-full h-30 w-30 flex justify-center items-center square-30 z-1k"
                      onClick={() => decrementQuantity(index)}
                    >
                      -
                    </button>
                  ) : (
                    ""
                  )}

                  {product.qte > 0 ? (
                    <div className="text-center text-gray-600 rounded-full absolute w-full bg-aef7ee bottom-3 z-100">
                      {" "}
                      {product.qte}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="text-sm mt-3">{product.name}</div>
                <div className="text-sm font-semibold mt-1">{product.price} Dh</div>
              </div>
            ))}
          </div>

          {isLoading ? (
            <div className="mt-4 mb-4">
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <button
                className="w-full px-4 py-2 rounded mt-7 bg-black text-yellow-200"
                onClick={() => {
                  console.log(products);
                  handleUpsell();
                }}
              >
                Termine ma commande
              </button>
              <button
                className="w-full bg-gray-200 text-gray-600 px-4 py-2 rounded mt-2"
                onClick={handleUpsell}
              >
                Non merci
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpsellModal;
