/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgressLoader from "./Loader";
import UpsellModal from "./upsellModal";
import PromoCodeInput from "./promocodeInput";

function Form({ id, quantity, prd }) {
  const [formData, setFormData] = useState({
    product_id: id,
    fullname: "",
    mobile: "",
    address: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPack, setSelectedPack] = useState(prd?.packs[0]);
  const [showUpSell, setShowUpSell] = useState(false);
  const [promoCode, setPromoCode] = useState()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const targetDiv = document.getElementById("targetDiv");

    const handleScroll = () => {
      if (targetDiv) {
        const targetRect = targetDiv.getBoundingClientRect();
        setIsSticky(targetRect.top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTarget = () => {
    const targetDiv = document.getElementById("targetDiv");
    if (targetDiv) {
      const targetY = targetDiv.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: targetY, behavior: "smooth" });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    console.log(promoCode);
    // return;
    event.preventDefault();
    setIsLoading(true);
    fbq("track", "AddToCart", { value: prd.price, currency: "MAD" });
    fbq("track", "AddPaymentInfo", { value: prd.price, currency: "MAD" });
    const promo_code = promoCode ? promoCode.code : '';
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api2.lokap.co/api/orders/create",
      // url: "http://127.0.0.1:4000/api/orders/create",
      headers: {
        "x-xrf-token":
          "tNV7R4wsuuCx6c2oOR2sMhaGgZ98HkI9iRhMzhrwqfbmgbHjJSG82XXokWsk4980ZoD9sABTxMDTutOVYzV85oPElqP1TiBgWDLoV7rSZMlkZkoYZE5u4ue2usNX5AtALE45vI16JSwzHtzeDCKsjCmKFzsoyBTDGE1ZsGOPvFsfrI6lZUSWQUxPKrCc9xGLOejxB22G",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ...formData,
        qte: quantity,
        pack: selectedPack.title,
        promo_code
      }),
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsLoading(false);
        setShowSuccessMessage(true);
        setFormData(
          {
            product_id: id,
            fullname: "",
            mobile: "",
            address: "",
          },
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000)
        );
        localStorage.setItem("fullname", JSON.stringify(formData.fullname));
        // console.log(response.data.success.generatedId);
        localStorage.setItem("ordereId", response.data.success.generatedId);
        fbq("track", "Purchase", { value: prd.price, currency: "MAD" });

        // fbq("track", "Lead", { /value: prd.price, currency: "MAD" });
        if(id === "6" || id === "5" ){
          navigate("/thankyou-page");
        }else {
          setShowUpSell(true);
        }
        // fbq('track', 'Lead')
        // navigate("/thankyou-page");
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      <UpsellModal isOpen={showUpSell} />
      <form
        className="flex flex-col gap-3 p-2 lokap-div"
        onSubmit={handleSubmit}
      >
        <span>Choisir un pack :</span>
        <div className="mr-10 m-grid">
          {prd?.packs.map((pack, index) => {
            return (
              <div
                className={`flex w-[170px] mt-2 justify-between bg-white p-2 px-3 rounded-xl relative ${
                  selectedPack.id === index + 1 ? "active" : ""
                }`}
              >
                <button
                  type="button"
                  // disabled={quantity === 1}
                  onClick={() => {
                    setSelectedPack(pack);
                  }}
                  className={`${false ? "opacity-50" : ""}`}
                >
                  {pack.title}
                </button>
                {pack.isPopular ? (
                  <span className={`highlight ${pack.class}`}>
                    {pack.message}
                  </span>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-6 gap-2 md:gap-0 items-center mt-10">
          <label className="font-semibold col-span-2" htmlFor="fullName">
            Nom et Prénom <span className="text-red">*</span>
          </label>
          <input
            className="col-span-4 p-2 border border-gray-200 bg-gray-50 rounded-md"
            placeholder="Nom et Prénom"
            type="text"
            id="fullName"
            value={formData.fullname}
            name="fullname"
            onChange={handleChange}
            required
          />
        </div>
        <div className="grid grid-cols-6 items-center">
          <label className="font-semibold col-span-2" htmlFor="mobileNumber">
            Téléphone <span className="text-red">*</span>
          </label>
          <input
            className="col-span-4 p-2 border border-gray-200 bg-gray-50 rounded-md"
            placeholder="06xxxxxxxx"
            type="tel"
            minLength={10}
            id="mobileNumber"
            value={formData.mobile}
            name="mobile"
            onChange={handleChange}
            required
          />
        </div>
        {/* <div className="grid grid-cols-6 items-center">
        <label className="font-semibold col-span-2" htmlFor="role">Profession :</label>
        <input
          className="col-span-4 p-2 border border-gray-200 bg-gray-50 rounded-md"
          placeholder='Profession'
          type="text"
          id="role"
          name="role"
          value={formData.role}
          onChange={handleChange}
          required
        />
      </div> */}
        {/* <div className="grid grid-cols-6 items-center">
        <label className="font-semibold col-span-2" htmlFor="address">Address :</label>
        <input
          className="col-span-4 p-2 border border-gray-200 bg-gray-50 rounded-md"
          placeholder='Address'
          type="text"
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
      </div> */}
        <div className="grid grid-cols-6 items-center">
          <label className="font-semibold col-span-2" htmlFor="city">
            Ville <span className="text-red">*</span>
          </label>
          <input
            className="col-span-4 p-2 border border-gray-200 bg-gray-50 rounded-md"
            placeholder="Ville"
            type="text"
            id="city"
            value={formData.address}
            name="address"
            onChange={handleChange}
            required
          />
        </div>
        <PromoCodeInput onSuccess={setPromoCode}/>
        <div
          id="targetDiv"
          className="bg-black bg-orange-500 cursor-pointer text-[#EEFAB0] text-center py-3 rounded-lg mt-4 hover:bg-orange-600 hover:scale-105 transition-all duration-300"
          type="submit"
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgressLoader /> : "Commander"}
        </div>
        <div className="h-4">
          {showSuccessMessage && (
            <p className="text-green-500 text-center">
              Votre commande a été envoyée avec succès
            </p>
          )}
        </div>
        {isSticky ? (
          <button
            onClick={scrollToTarget}
            className={`${"fixed bottom-4 left-4 right-4"} z-1k bg-black text-[#EEFAB0] text-center hover:bg-[#1D1D1D] hover:scale-105 py-2 px-4 rounded-md`}
          >
            Commander
          </button>
        ) : (
          ""
        )}
      </form>
    </>
  );
}

export default Form;
