/* eslint-disable */
import React, { useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

function ProductImages({ images}) {

  const splideRef = useRef(null);

  const handleSplideIndex = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
    }
  };

  return (
    <>
      <Splide
        options={{
          rewind: true,
          gap: '1rem',
        }}
        ref={splideRef}
        className="-mt-12"
      >
        {images.map((image, index) => (
          <SplideSlide key={index}>
            <img className="rounded-lg" src={image} alt="product" />
          </SplideSlide>
        ))}
      </Splide>
      <div className="flex flex-wrap gap-2 justify-center mt-4">
        {images.map((image, index) => (
          <img
            key={index}
            className="w-30 h-20 object-cover rounded-lg cursor-pointer"
            src={image}
            alt="product"
            onClick={() => handleSplideIndex(index)}
          />
        ))}
      </div>
    </>
  );
}

export default ProductImages;
