/* eslint-disable */
import React from "react";
import { AiFillStar } from "react-icons/ai";
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import Description from "../components/Description";
import FAQ from "../components/FAQ";
import ColoredTxt from "../components/ColoredTxt";
import WhatsAppButton from "../components/WhatsAppButton";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import heroImg from "../assets/card-hero.png";
import vidGif from "../assets/RPReplay_Final1686156119.gif";
import profileVideoPreview from "../assets/ezgif-1-4aea476fa4.mp4";
// import { motion } from "framer-motion";
import AnimationDiv from "../components/AnimatedDiv";
import products from "../helpers/ConstsData";
import heroVideo from "../assets/profile-video-preview.mp4";
import AnnouncementBar from "../components/AnnouncementBar";
function Home() {
  const navList = [
    {
      url: "#hero",
      text: "Accueil",
    },
    {
      url: "#nos-prix",
      text: "Nos-Prix",
    },
    {
      url: "#features",
      text: "Fonctionnalités",
    },
    {
      url: "#faq",
      text: "FAQ",
    },
  ];

  const navigate = useNavigate();

  const [hoveredImg, setHoveredImg] = React.useState(null);

  const handleMouseOver = (index) => {
    setHoveredImg(index);
  };

  const handleMouseOut = () => {
    setHoveredImg(null);
  };

  return (
    <>
    <AnnouncementBar/>
      <Navbar navList={navList} showDemo={true} />

      {/* hero */}
      <section
        id="hero"
        style={{ scrollMarginTop: "10rem" }}
        className="container px-4 mx-auto mt-24 max-w-[1200px]"
      >
        <div className="flex flex-col gap-28 justify-center items-center md:grid md:grid-cols-2">
          <AnimationDiv>
            <div className="flex flex-col gap-10 md:text-left sm:text-center">
              <h1 className="text-5xl">
                <b className="gradient-text font-black">LOKAP</b>
                <br />
                Votre dernière carte de visite.
              </h1>
              <p>
                Échangez sans contact grâce aux technologies NFC et QR Code et
                partagez en un seul geste l&apos;ensemble de vos informations.
                De votre fiche contact à vos outils commerciaux (réseaux
                sociaux, plaquettes PDF, avis sur Google, etc.) Vous n&apos;êtes
                plus qu&apos;à une carte de votre réseau professionnel.
              </p>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <AiFillStar size={23} color="#ffe234" />
                  <AiFillStar size={23} color="#ffe234" />
                  <AiFillStar size={23} color="#ffe234" />
                  <AiFillStar size={23} color="#ffe234" />
                  <AiFillStar size={23} color="#ffe234" />
                </div>
                <p className="italic">
                  {`"J'ai reçu ma LOKAP et je suis vraiment satisfait du résultat.
                  C'est un outil de communication très pratique et très professionnel.
                  Je recommande !"`}
                </p>
                <p>— Youssef Ali, Commercial</p>
              </div>
            </div>
          </AnimationDiv>

          <AnimationDiv>
            <div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="flex justify-center md:justify-end"
            >
              <video
                className="w-full"
                src={heroVideo}
                muted
                autoPlay
                loop
                alt="card"
              />
            </div>
          </AnimationDiv>
        </div>
      </section>

      {/* description */}
      <section className="container flex flex-col gap-4 px-4 mx-auto my-44 text-center max-w-screen-lg">
        <AnimationDiv>
          <span className="text-4xl">
            Trouvez la carte <ColoredTxt text="LOKAP" /> qui vous convient
          </span>
          <p>
            {
              "Avec la LOKAP, vous pouvez créer la carte de visite connectée qui vous convient parfaitement grâce à une personnalisation complète. Choisissez parmi différents support, importez votre propre graphisme pour refléter l'identité visuelle de votre entreprise."
            }
          </p>
        </AnimationDiv>
      </section>

      {/* cards */}
      <section
        id="nos-prix"
        style={{ scrollMarginTop: "7rem", maxWidth:'800px' }}
      
        className="container flex flex-col md:flex-row gap-6 px-4 mx-auto my-16"
      >
        {/* {products.map((product) => {
          if (product.isCard)
            return (
              <AnimationDiv>
                <div className="flex flex-col px-4 h-full py-16 items-center gap-8 rounded-xl bg-gray-100 shadow-md">
                  <div className="text-center flex flex-col gap-3 px-4">
                    <span className="text-3xl font-extrabold ">
                      {product.name}
                    </span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.subDescription,
                      }}
                    />
                  </div>
                  <img
                    src={product.gif}
                    alt={product.name}
                    className="w-[80%] rounded-xl"
                  />
                  <div className="text-center">
                    <p className="text-lg">À partir de :</p>
                    <p className="text-3xl font-bold">{product.price} MAD</p>
                  </div>
                  <a
                    href={"/product/" + product.slug}
                      className="w-full max-w-[250px] mt-4"
                      // onClick={() => {
                      //   console.log("hey");
                      //   navigate("/product/" + product.slug);
                      //   // window.open('/product/' + product.slug, '_self');
                      // }}
                    >
                      <Button btnTxt="Voir plus" />
                    </a>
                  <Description pdtID={parseInt(product.id)} />
                </div>
              </AnimationDiv>
            );
        })} */}
        {products.map((product) => {
          if (product.isCard) {
            return (
              <AnimationDiv key={product.id}>
                <div className="flex flex-col px-4 h-full py-16 items-center gap-8 rounded-xl bg-gray-100 shadow-md ">
                  <div className="text-center flex flex-col gap-3 px-4">
                    <span className="text-3xl font-extrabold">
                      {product.name}
                    </span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: product.subDescription,
                      }}
                    />
                  </div>
                  <img
                    src={product.gif}
                    alt={product.name}
                    className="w-[80%] rounded-xl"
                  />
                  <div className="text-center">
                    <p className="text-lg">À partir de :</p>
                    <p className="text-3xl font-bold">{product.price} Dh</p>
                  </div>

                  <a
                    href={"/product/" + product.slug}
                    className="w-full max-w-[250px] mt-4"
                  >
                    <Button btnTxt="Voir plus" />
                  </a>
                  <ul className="text-left mt-4">
                    {product.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <svg
                          fill="#000000"
                          width="30px"
                          height="30px"
                          viewBox="0 0 24 24"
                          id="check"
                          data-name="Line Color"
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon line-color"
                        >
                          <polyline
                            id="primary"
                            points="5 12 10 17 19 8"
                            style={{
                              fill: "none",
                              stroke: "#F87315",
                              strokeLinecap: "round",
                              strokeLinejoin: "round",
                              strokeWidth: 2,
                            }}
                          />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </AnimationDiv>
            );
          }
        })}
      </section>

      {/* nos produits */}
      <section className="container flex flex-col gap-12 mx-auto my-44 text-center max-w-[1200px]">
        <span className="text-4xl">Nos produits</span>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-6 px-4 mt-10">
          {products.map((product, index) => {
            if (!product.isCard) {
              return (
                <AnimationDiv>
                  <div
                    key={product.id}
                    className="flex flex-col px-4 h-full py-6 items-center gap-1 rounded-xl bg-gray-100 shadow-md"
                  >
                    <img
                      src={
                        hoveredImg === index ? product.imageHover : product.gif
                      }
                      alt={product.name}
                      className="w-[80%] rounded-xl"
                      onMouseOver={() => handleMouseOver(index)}
                      onMouseOut={handleMouseOut}
                    />
                    <div className="text-center px-4">
                      <span className="text-2xl font-extrabold ">
                        {product.name}
                      </span>
                    </div>
                    <div className="text-center flex gap-3 items-end">
                      <span className="text-lg line-through">
                        {product.oldPrice} MAD
                      </span>
                      <br></br>
                      <span className="text-xl font-bold">
                        {product.price} MAD
                      </span>
                    </div>
                    <a
                      href={"/product/" + product.slug}
                      className="w-full max-w-[250px] mt-4"
                      // onClick={() => {
                      //   console.log("hey");
                      //   navigate("/product/" + product.slug);
                      //   // window.open('/product/' + product.slug, '_self');
                      // }}
                    >
                      <Button btnTxt="Voir plus" />
                    </a>
                  </div>
                </AnimationDiv>
              );
            }
          })}
        </div>
      </section>

      {/* qr */}
      <section
        id="features"
        style={{ scrollMarginTop: "10rem", }}
        className="container max-w-[900px] items-center flex flex-col gap-10 px-4 mx-auto my-28"
      >
        <span className="text-4xl z-20 text-center">
          Partagez en un seul geste, <ColoredTxt text="sans contact." />
        </span>

        <div className="relative flex flex-col mt-16 md:mt-52 md:flex-row gap-[13%]">
          <div className="flex flex-col items-center p-10 gap-4 bg-gray-100 rounded-xl">
            <span className="text-3xl font-bold">Sans Contact</span>
            <img
              alt="animited phone"
              src="https://uploads-ssl.webflow.com/6399e036f584ee550ea31dcd/64bfc544368e3149d61d473a_IPHONE.gif"
              className="w-[130px]"
            />
            <p className="text-center">
              La technologie
              <b>NFC</b>
              (ou sans contact) est directement integré dans votre LOKAP grâce à
              une puce haute qualité et sécurisé.
            </p>
          </div>

          <div 
          style={{display: 'flex'}}
          className="md:absolute flex flex-col justify-center items-center md:-top-[75%] md:left-[50%] text-center md:-translate-x-1/2 md:w-[50%]">
            {/* <img src={vidGif} alt="phone gif" width={240} /> */}
            {/* <img src={vidGif} alt="phone gif" width={240} /> */}
            <video
              src={profileVideoPreview}
              width={240}
              autoPlay
              loop
              muted
            ></video>
          </div>

          <div className="flex flex-col items-center p-10 gap-4 bg-gray-100 rounded-xl">
            <span className="text-3xl font-bold">QR Code</span>
            <img
              alt="animited phone"
              src="https://uploads-ssl.webflow.com/6399e036f584ee550ea31dcd/64bfc56c1406f7a5ffd1137f_qr-code.gif"
              className="w-[130px]"
            />
            <p className="text-center">
              Grâce au <b>QR</b> Code, partagez simplement vos informations
              depuis votre LOKAP à vos interlocuteurs avec un smartphone ancien.
            </p>
          </div>
        </div>

        <div className="rounded-lg w-full">
          <iframe
            className="w-full h-[200px] md:h-[500px] rounded-xl"
            src="https://www.youtube.com/embed/o_CcKrOiPCQ?si=md2WKBqeswSCw9BO"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </section>

      {/* recycle */}
      <AnimationDiv>
        <section className="container flex flex-col items-center gap-10 px-4 mx-auto my-44 text-center max-w-[900px]">
          <span className="text-4xl">
            Des supports durables pour une <br />
            <ColoredTxt text="Entreprise Responsable." />
          </span>

          <div className="flex flex-col md:flex-row gap-6 mt-10">
            <div className="flex flex-col items-center gap-3 py-8 px-12 bg-gradient-to-b from-[#4ee5d782] via-[#f0ff8287] bg-[#f0ff8210] to-transparent rounded-xl shadow-md">
              <span className="text-3xl font-bold">PVC recyclé</span>
              <p>
                En utilisant des matériaux recyclés pour fabriquer nos cartes de
                visite digitales, on{" "}
                <strong>réduit l'impact environnemental</strong>
                <br />
              </p>
              <img
                src="https://uploads-ssl.webflow.com/6399e036f584ee550ea31dcd/64392434bff7f9f111426245_recyclage-p-500.webp"
                alt="recycle"
                className="w-[150px]"
              />
            </div>

            <div className="flex flex-col items-center gap-3 py-8 px-12 bg-gradient-to-b from-[#c3aef8] via-[#e1d2b187] bg-[#e1d2b187] to-transparent rounded-xl shadow-md">
              <span className="text-3xl font-bold">
                Une Carte, Mille Rencontres
              </span>
              <p>
                Une seule carte de visite digitale pour des milliers
                d'opportunités,
                <strong>réduisant ainsi notre empreinte écologique.</strong>
                <br />
              </p>
              <img
                src="https://uploads-ssl.webflow.com/6399e036f584ee550ea31dcd/644b855f7e78e1fdf2cb66a9_arbre-p-1080.webp"
                alt="recycle"
                className="w-[150px]"
              />
            </div>
          </div>
        </section>
      </AnimationDiv>

      {/* faq */}
      <AnimationDiv>
        <FAQ />
      </AnimationDiv>

      {/* whatsapp */}
      <WhatsAppButton />

      {/* footer */}
      <Footer />
    </>
  );
}

export default Home;
