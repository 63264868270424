import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const [countdown, setCountdown] = useState(24 * 60 * 60 * 1000); // 1 day in milliseconds

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1000);
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const days = Math.floor(countdown / (24 * 60 * 60 * 1000));
  const hours = Math.floor((countdown % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((countdown % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((countdown % (60 * 1000)) / 1000);

  return (
    <div>
      <p>🚨 Cette offre exclusive expirera dans {minutes} minutes, et {seconds} secondes.</p>
    </div>
  );
};

export default CountdownTimer;
