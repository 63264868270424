import React from "react";
import PropTypes from "prop-types";
import products from "../helpers/ConstsData";

function Description({ pdtID }) {
  let deliveryTime;
  const product = products.find((p) => parseInt(p.id) === pdtID);

  if (pdtID === 1) {
    deliveryTime = <strong>moins de 2 jours</strong>;
  } else if (pdtID === 2) {
    deliveryTime = <strong>moins de 4 jours</strong>;
  } else {
    deliveryTime = <strong>5 jours ouvrés</strong>;
  }

  let message = "";
  if (pdtID === 2) {
    message = (
      <p>
        🎨 Ajoutez votre nom et votre rôle, choisissez parmi 3 couleurs
        différentes.
        <br />
        <br />
      </p>
    );
  } else if (pdtID === 3) {
    message = (
      <p>
        🎨 Personnalisation totale : créez une carte unique en ajoutant votre
        logo et en choisissant son design
        <br />
        <br />
      </p>
    );
  }

  return (


      <div
        dangerouslySetInnerHTML={{
          __html: product.description,
        }}
      />
  );
}

Description.propTypes = {
  pdtID: PropTypes.number.isRequired,
};

export default Description;
