import React from 'react';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

function WhatsAppButton() {
  return (
    <div className="fixed bottom-4 right-4 z-10">
      <WhatsAppWidget
        phoneNumber="+212632722100"
        message={'Bonjour, 👋🏼 \n\nComment je peux vous aider?'}
        sendButtonText="Envoyer"
        inputPlaceHolder="Ecrivez votre message"
        replyTimeText="Généralement répond en quelques minutes"
        companyName="Lokap"
      />
    </div>
  );
}

export default WhatsAppButton;
