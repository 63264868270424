/* eslint-disable */
import React from 'react';
import Navbar from '../components/Navbar';
import AnnouncementBar from '../components/AnnouncementBar';

function Thanks() {
  const navList = [
    {
      url: "#hero",
      text: "Accueil",
    },
    {
      url: "#nos-prix",
      text: "Nos-Prix",
    },
    {
      url: "#features",
      text: "Fonctionnalités",
    },
    {
      url: "#faq",
      text: "FAQ",
    },
  ];
      
  return (
    <>
    <AnnouncementBar />
    <Navbar navList={navList} showDemo={false} />
    <div className="flex items-center justify-center p-12 text-center">
      <div>
        <div className="flex flex-col items-center space-y-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-green-600 w-28 h-28"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={1}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h1 className="text-4xl font-bold">Merci pour Votre Commande!</h1>
          <p>
          Nous vous remercions de votre commande! Pour une confirmation plus rapide, n'hésitez pas à nous contacter sur WhatsApp. Nous sommes là pour vous aide
          </p>
          <a href='https://wa.link/xyvszr' className="inline-flex items-center px-4 py-2 text-white bg-green-500 border-none rounded rounded-full hover:bg-gray-700 focus:outline-none focus:ring">
            <span className="text-sm font-medium">Confirmer sur WhatsApp</span>
          </a>
          <a href='/' className="inline-flex items-center px-4 py-2 text-white bg-black border border-black rounded rounded-full hover:bg-gray-700 focus:outline-none focus:ring">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 h-3 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            <span className="text-sm font-medium">Accueil</span>
          </a>
        </div>
      </div>
    </div>
    </>
  );
}

export default Thanks;
