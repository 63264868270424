/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { BiPlus } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import dots from "../assets/dots.png";
import lokap from "../assets/logo-lokap.png";
import EditImage from "../components/editImage/EditImage";
import initialState from "../helpers/initialState";
import SvgQrCode from "../helpers/SvgQrCode";

import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

import PickColor from "../components/editImage/PickColor";
import NfcCode from "../helpers/NfcCode";
import Button from "../components/Button";

function Card() {
  const textColor = ["#FFFFFF", "#000000", "#C0C0C0", "#FFD700"];

  const ultraColors = Object.values(initialState.ultra.colors);

  const [color, setColor] = useState(initialState.standard.colors.Black);
  const [txColor, setTxColor] = useState("#FFFFFF");
  const [roleColor, setRoleColor] = useState("#D85759");
  const [name, setName] = useState( localStorage.getItem("fullname").replace(/"/g, "") || "your name");
  const [role, setRole] = useState("your role");
  const [qrnfc, setQrnfc] = useState("white");
  // const [imageData, setImageData] = useState(null);

  const lokapTxt = "lokap dot com";
  const [quantity, setQuantity] = useState(1);

  const [cardPicker, setCardPicker] = useState(false);
  const [txPicker, setTxPicker] = useState(false);
  const [rolePicker, setRolePicker] = useState(false);
  const [qrPicker, setQrPicker] = useState(false);

  const [recto, setRecto] = useState(true);
  const [selectedOption, setSelectedOption] = useState("ULTRA");

  const [canvasReady, setCanvasReady] = useState(false);
  const [canvasElement, setCanvasElement] = useState(null);

  const componentToExportRef = useRef(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === "STANDAR") {
      setTxColor("#ffffff");
      setColor(initialState.standard.colors.Black);
      setQrnfc("#ffffff");
    }
    if (option === "PERSONALISABLE") {
      setColor(initialState.standard.colors.Black);
      setQrnfc("#ffffff");
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleCanvasReady = (canvasRef) => {
    // Render the canvas at the desired location within the App component
    if (canvasRef.current) {
      setCanvasElement(canvasRef.current);
      setCanvasReady(true);
    }
  };

  useEffect(() => {
    if (canvasReady && canvasElement) {
      // Get the container element where you want to append the canvas
      const container = document.getElementById("canvas-container");

      if (container) {
        // Append the canvas to the container
        container.appendChild(canvasElement);
        const previousChild = document.getElementById("lokap-logo");
        container.removeChild(previousChild);
      }
    }
  }, [canvasReady, canvasElement]);

  const handleExportClick = () => {
    const componentToExport = componentToExportRef.current;

    // Use html2canvas to capture the component as an image
    html2canvas(componentToExport).then((canvas) => {
      // Convert canvas to a data URL
      const dataUrl = canvas.toDataURL("image/png");

      // Create a download link for the user to download the image
      const downloadLink = document.createElement("a");
      downloadLink.href = dataUrl;
      downloadLink.download = "exported-image.png";
      downloadLink.click();

      // Set the image data in the state
      // setImageData(dataUrl);
      // console.log(dataUrl);
    });
  };

  const splideRef = useRef(null);

  const handleSplideIndex = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
    }
  };

  // Tooltip
  const divRef = useRef(null);
  const divRefTow = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipTow, setShowTooltipTow] = useState(false);
  const getCursorPosition = (event) => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      console.log("x: " + x + " y: " + y);

      setTooltipPosition({ x, y });
      setShowTooltip(true);
    }
  };
  const getCursorPositionTwo = (event) => {
    if (divRefTow.current) {
      const rect = divRefTow.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      console.log("x: " + x + " y: " + y);

      setTooltipPosition({ x, y });
      setShowTooltipTow(true);
    }
  };
  const hideTooltip = () => {
    setShowTooltip(false);
    setShowTooltipTow(false);
  };

  return (
    <div className="container mx-auto max-w-screen-lg my-20">
      <div className=" flex flex-col md:flex-row gap-10 md:gap-5">
        <div className="flex flex-col px-2 gap-4 md:max-w-[50%]">
          {/* recto verso */}
          <div className="flex gap-10 font-semibold">
            <button
              type="button"
              className={`cursor-pointer py-2 w-full text-center rounded-lg border ${
                recto ? " border-gray-600" : "border-gray-300"
              } `}
              onClick={() => {
                setRecto(true);
                handleSplideIndex(0);
              }}
            >
              <span className="select-none">Recto</span>
            </button>
            <button
              type="button"
              className={`cursor-pointer py-2 w-full text-center rounded-lg border ${
                !recto ? "border-gray-600" : " border-gray-300"
              } `}
              onClick={() => {
                setRecto(false);
                handleSplideIndex(1);
              }}
            >
              <span className="select-none">Verso</span>
            </button>
          </div>

          {/* card slider */}
          <div className="w-full overflow-hidden block bg-gray-100 rounded-xl h-80 flex justify-center items-center">
            <Splide aria-label="My Nfc Card" ref={splideRef}>
              <SplideSlide>
                <div
                  id="component-to-export"
                  className={`relative overflow-hidden h-48 w-80 mx-auto rounded-xl text-white bg-[${color}] drop-shadow-xl`}
                >
                  <span
                    className={`absolute text-[${txColor}] font-bold top-[6%] left-[5%]`}
                  >
                    {selectedOption === "STANDAR" ? lokapTxt : name}
                  </span>
                  <span
                    className={`absolute font-bold text-xs text-[${roleColor}] tracking-wide top-[16%] left-[5%]`}
                  >
                    {selectedOption === "STANDAR" ? "" : role}
                  </span>
                  <img
                    className="absolute -bottom-14 transform scale-x-[-1]"
                    alt="dots"
                    src={dots}
                  />
                  <div className="absolute right-[5%] w-5 top-[8%]">
                    <NfcCode color={qrnfc} />
                  </div>
                  {/* <img
                    alt="img"
                    className="absolute bottom-[5%] left-[4%] w-[25%] "
                    src={qr}
                  /> */}
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  id="component-to-export"
                  ref={componentToExportRef}
                  className={`relative overflow-hidden h-48 w-80 mx-auto rounded-xl text-white bg-[${color}] drop-shadow-xl`}
                >
                  <img
                    className="absolute  opacity-50 -top-[50%] transform rotate-180"
                    alt="dots"
                    src={dots}
                  />
                  <div className="absolute bottom-[30%] right-[4%] w-[25%]">
                    <SvgQrCode qrCodeColor={qrnfc} />
                  </div>
                  {selectedOption === "ULTRA" ? (
                    ""
                  ) : (
                    <img
                      alt="lokap logo"
                      src={lokap}
                      className="absolute bottom-2 h-[15%] left-4"
                    />
                  )}
                  <span className="absolute text-xs bottom-0 h-[15%] right-4">
                    lokap.co
                  </span>
                  <div
                    id="canvas-container"
                    className={`absolute left-[5%] bottom-[18%] ${
                      selectedOption === "ULTRA" ? "" : "opacity-0"
                    }`}
                  >
                    {/* The canvas will be appended here */}
                    <img
                      id="lokap-logo"
                      alt="lokap logo"
                      src={lokap}
                      className="w-40"
                    />
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>

        <div className="flex flex-col w-full gap-8 px-3">
          {/* lokap card */}
          <div className="w-full flex flex-col gap-3 px-2 py-3 md:p-3 bg-[#F0F0E9] rounded-lg shadow-sm">
            <span>Lokap</span>
            <span className="text-4xl font-bold">Lokap {selectedOption}</span>
            <div className="flex flex-wrap gap-3 items-center">
              <span className="line-through">
                Dh{" "}
                {selectedOption === "STANDAR"
                  ? "299"
                  : selectedOption === "ULTRA"
                  ? "599"
                  : "399"}
                .00 MAD
              </span>
              <span className="font-semibold text-lg">
                Dh{" "}
                {selectedOption === "STANDAR"
                  ? "199"
                  : selectedOption === "ULTRA"
                  ? "499"
                  : "299"}
                .00 MAD
              </span>
              <span className="py-1 px-5 rounded-full text-[#F0F0E9] bg-[#583852]">
                En vent
              </span>
            </div>
          </div>

          {/* select type of cards */}
          <div className="grid grid-cols-3 gap-2 md:gap-3 text-xs">
            <div
              className={`flex items-center pl-2 border border-gray-200 rounded-xl ${
                selectedOption === "STANDAR" ? "border-gray-600" : ""
              }`}
            >
              <input
                type="radio"
                name="option"
                id="standar"
                value="STANDAR"
                checked={selectedOption === "STANDAR"}
                onChange={() => handleOptionChange("STANDAR")}
                className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-black"
              />
              <label
                htmlFor="standar"
                className="w-full py-4 ml-1 font-medium text-gray-900"
              >
                STANDAR
              </label>
            </div>

            <div
              className={`flex items-center pl-2 border border-gray-200 rounded-xl ${
                selectedOption === "PERSONALISABLE" ? "border-gray-600" : ""
              }`}
            >
              <input
                type="radio"
                name="option"
                id="personalisable"
                value="PERSONALISABLE"
                checked={selectedOption === "PERSONALISABLE"}
                onChange={() => handleOptionChange("PERSONALISABLE")}
                className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-black"
              />
              <label
                htmlFor="personalisable"
                className="w-full py-4 ml-1 font-medium text-gray-900"
              >
                PERSO
              </label>
            </div>

            <div
              className={`relative flex items-center pl-2 border border-gray-200 rounded-xl ${
                selectedOption === "ULTRA" ? "border-gray-600" : ""
              }`}
            >
              <input
                type="radio"
                name="option"
                id="ultra"
                value="ULTRA"
                checked={selectedOption === "ULTRA"}
                onChange={() => handleOptionChange("ULTRA")}
                className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-black"
              />
              <label
                htmlFor="ultra"
                className="w-full py-4 ml-1 font-medium text-gray-900"
              >
                ULTRA
              </label>
              <span className="absolute -top-4 -right-2 px-2 py-1 text-white font-bold rounded-full bg-[#D9E63B]">
                Populaire
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            {/* input name role & qrColor */}
            <div className="flex relative flex-col gap-3">
              {selectedOption === "STANDAR" ? (
                <div
                  ref={divRef}
                  onClick={getCursorPosition}
                  onMouseLeave={hideTooltip}
                  className="w-full h-full absolute top-0 right-0 bg-[#ffffff00] z-10"
                >
                  {showTooltip ? (
                    <div
                      className="absolute z-10"
                      style={{
                        top: `${tooltipPosition.y}px`,
                        left: `${tooltipPosition.x}px`,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        padding: "4px",
                        borderRadius: "4px",
                      }}
                    >
                      Those features aren't available on STANDAR
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {/* name */}
              <div
                className={`relative group flex flex-col gap-1 ${
                  selectedOption === "STANDAR" ? "opacity-50" : ""
                }`}
                onClick={() => handleSplideIndex(0)}
              >
                <div className="flex justify-between items-center">
                  <h2>Your name</h2>
                  <div className="flex gap-2 items-center">
                    {textColor.map((color) => (
                      <button
                        key={color}
                        type="button"
                        className={`w-4 h-4 rounded-full border border-gray-300 bg-[${color}]`}
                        onClick={() => setTxColor(color)}
                        aria-label="color"
                        disabled={selectedOption === "STANDAR" ? true : false}
                      />
                    ))}
                    <div
                      className={`relative w-6 h-6 ${
                        selectedOption !== "ULTRA"
                          ? "opacity-50"
                          : "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (selectedOption === "ULTRA") {
                          setTxPicker(true);
                        }
                      }}
                    >
                      <BiPlus
                        size={20}
                        className="absolute -z-10 top-[2px] left-0"
                      />
                    </div>
                  </div>
                </div>
                <input
                  className="bg-gray-100 rounded-md py-2 pl-3"
                  placeholder="your name"
                  value={name}
                  onChange={handleNameChange}
                  disabled={selectedOption === "STANDAR" ? true : false}
                />
              </div>

              {/* role */}
              <div
                className={`flex flex-col gap-1 ${
                  selectedOption === "STANDAR" ? "opacity-50" : ""
                }`}
                onClick={() => handleSplideIndex(0)}
              >
                <div className="flex justify-between items-center">
                  <h2>Your role</h2>
                  <div className="flex gap-2 items-center">
                    {textColor.map((color) => (
                      <button
                        key={color}
                        type="button"
                        className={`w-4 h-4 rounded-full border border-gray-300 bg-[${color}]`}
                        onClick={() => setRoleColor(color)}
                        aria-label="color"
                        disabled={selectedOption === "STANDAR" ? true : false}
                      />
                    ))}
                    <div
                      className={`relative w-6 h-6 ${
                        selectedOption !== "ULTRA"
                          ? "opacity-50"
                          : "cursor-pointer"
                      }`}
                      onClick={() => {
                        if (selectedOption === "ULTRA") {
                          setRolePicker(true);
                        }
                      }}
                    >
                      <BiPlus
                        size={20}
                        className="absolute -z-10 top-[2px] left-0"
                      />
                    </div>
                  </div>
                </div>
                <input
                  className="bg-gray-100 rounded-md py-2 pl-3"
                  placeholder="your role"
                  value={role}
                  onChange={handleRoleChange}
                  disabled={selectedOption === "STANDAR" ? true : false}
                />
              </div>

              {/* Qr & Nfc color  */}

              <div
                className={`flex justify-between items-center ${
                  selectedOption === "STANDAR" ? "opacity-50" : ""
                }`}
                onClick={() => handleSplideIndex(1)}
              >
                <h2>Qr & Nfc color :</h2>
                <div className="flex gap-2 items-center">
                  {textColor.map((color) => (
                    <button
                      key={color}
                      type="button"
                      className={`w-4 h-4 rounded-full border border-gray-300 bg-[${color}]`}
                      onClick={() => setQrnfc(color)}
                      aria-label="color"
                      disabled={selectedOption === "STANDAR" ? true : false}
                    />
                  ))}
                  <div
                    className={`relative w-6 h-6 ${
                      selectedOption !== "ULTRA"
                        ? "opacity-50"
                        : "cursor-pointer"
                    }`}
                    onClick={() => {
                      if (selectedOption === "ULTRA") {
                        setQrPicker(true);
                      }
                    }}
                  >
                    <BiPlus
                      size={20}
                      className="absolute -z-10 top-[2px] left-0"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* input image  */}
            <div
              className="flex relative flex-col gap-2"
            >
              {selectedOption !== "ULTRA" ? (
                <div
                  ref={divRefTow}
                  onClick={getCursorPositionTwo}
                  onMouseLeave={hideTooltip}
                  className="w-full h-full absolute top-0 left-0 z-10 bg-[#ffffff50]"
                >
                  {showTooltipTow ? (
                    <div
                      className="absolute z-10"
                      style={{
                        top: `${tooltipPosition.y}px`,
                        left: `${tooltipPosition.x}px`,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        padding: "4px",
                        borderRadius: "4px",
                      }}
                    >
                      This feature is available only on ULTRA
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <span className="text-lg font-semibold">Logo</span>
              <EditImage
                selectedOption={selectedOption}
                handleSplideIndex={handleSplideIndex}
                onCanvasReady={handleCanvasReady}
              />
            </div>

            {/* Card color */}
            <div
              className={`flex flex-col gap-2 ${
                selectedOption === "STANDAR" ? "opacity-50" : ""
              }`}
            >
              <h2>
                Color : <span className="font-bold capitalize">{color}</span>
              </h2>
              <div className="flex flex-wrap gap-2 text-white items-center">
                {ultraColors.map((col) => (
                  <button
                    type="button"
                    key={col}
                    className={`flex justify-center p-3 rounded-md items-center bg-gray-100 cursor-pointer border-2 border-gray-400 ${
                      col === color ? "" : "border-opacity-0"
                    } ${selectedOption === "STANDAR" ? "cursor-default" : ""} `}
                    onClick={() => setColor(col)}
                    disabled={selectedOption === "STANDAR" ? true : false}
                  >
                    <div
                      className={`text-center h-6 w-12 rounded-md bg-[${col}]`}
                    />
                  </button>
                ))}
                <button
                  type="button"
                  className={`relative w-6 h-6 ${
                    selectedOption !== "ULTRA"
                      ? "opacity-50 cursor-default"
                      : ""
                  }`}
                  onClick={() => {
                    if (selectedOption === "ULTRA") {
                      setCardPicker(true);
                    }
                  }}
                >
                  <BiPlus
                    size={25}
                    color="black"
                    className="absolute -z-10 top-0 left-[20%]"
                  />
                </button>
              </div>
            </div>

            {/* Quantity & pricing */}
            <div className="flex  bg-[#F0F1E9] text-lg text-[#2D2A38] rounded-lg p-3">
              <div className="mr-10">
                <span>s :</span>
                <div className="flex w-[150px] mt-2 justify-between bg-white p-2 px-5 rounded-xl border border-gray-400">
                  <button
                    disabled={quantity === 1 ? true : false}
                    onClick={() => setQuantity(quantity - 1)}
                    className={quantity === 1 ? "cursor-not-allowed	" : ""}
                  >
                    -
                  </button>
                  <span>{quantity}</span>
                  <button onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
              </div>

              <div className="w-[1px] h-full hidden sm:block bg-gray-300 mr-10"></div>

              <div className="h-full flex flex-col gap-2">
                <div>Totale :</div>
                <span className="text-3xl font-bold">
                  {quantity * 499}.00<span className="text-xl"> MAD</span>
                </span>
              </div>
            </div>

            <Button btnTxt="Finaliser ma commande" />
          </div>

          <div className="mt-4">
            <button type="button" onClick={handleExportClick}>
              Export as Image
            </button>
          </div>
        </div>
      </div>

      {/* color picker */}
      <div
        className={`${
          txPicker ? "fixed" : "hidden"
        }  flex flex-col justify-center items-center top-0 left-0 w-full h-screen z-[9999] bg-[#000000e6]`}
      >
        <div className="flex flex-col items-end gap-1">
          <AiFillCloseCircle
            color="white"
            size={30}
            onClick={() => setTxPicker(false)}
          />
          <PickColor setTxColor={setTxColor} />
        </div>
      </div>

      <div
        className={`${
          rolePicker ? "fixed" : "hidden"
        } flex flex-col justify-center items-center top-0 left-0 w-full h-screen z-[9999] bg-[#000000e6]`}
      >
        <div className="flex flex-col items-end gap-1">
          <AiFillCloseCircle
            color="white"
            size={30}
            onClick={() => setRolePicker(false)}
          />
          <PickColor setRoleColor={setRoleColor} />
        </div>
      </div>

      <div
        className={`${
          qrPicker ? "fixed" : "hidden"
        } flex flex-col justify-center items-center top-0 left-0 w-full h-screen z-[9999] bg-[#000000e6]`}
      >
        <div className="flex flex-col items-end gap-1">
          <AiFillCloseCircle
            color="white"
            size={30}
            onClick={() => setQrPicker(false)}
          />
          <PickColor setQrnfc={setQrnfc} />
        </div>
      </div>

      <div
        className={`${
          cardPicker ? "fixed" : "hidden"
        }  flex flex-col justify-center items-center top-0 left-0 w-full h-screen z-[9999] bg-[#000000e6]`}
      >
        <div className="flex flex-col items-end gap-1">
          <AiFillCloseCircle
            color="white"
            size={30}
            onClick={() => setCardPicker(false)}
          />
          <PickColor setColor={setColor} />
        </div>
      </div>
    </div>
  );
}

export default Card;
