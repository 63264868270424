import React from 'react';
import PropTypes from 'prop-types';

function Button({ btnTxt }) {
  return (
    <button
      type="button"
      className="bg-[#F87315] w-full text-[#FFFFFF] text-center py-3 rounded-lg mt-4 hover:bg-[#1D1D1D] hover:scale-105 transition-all duration-300"
    >
      {btnTxt}
    </button>
  );
}

Button.propTypes = {
  btnTxt: PropTypes.string.isRequired,
};

export default Button;
