import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProdactPage from './pages/ProdactPage';
import Card from './pages/Card';
import Home from './pages/Home';
import './App.css';
import Thanks from './pages/Thanks';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path='*' element={<ProdactPage />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/product/:slug" element={<ProdactPage />} />
          {/* <Route path="/ultra" element={<Card />} /> */}
          <Route path="/thankyou-page" element={<Thanks />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
