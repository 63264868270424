/* eslint-disable */
import React, { useEffect } from "react";
import ProductHeader from "../components/ProductHeader";
import Form from "../components/Form";
import ProductImages from "../components/ProductImages";
import { useParams } from "react-router-dom";
import products from "../helpers/ConstsData";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";
import ColoredTxt from "../components/ColoredTxt";
import Description from "../components/Description";
import AnimatedDiv from "../components/AnimatedDiv";
import WhatsAppButton from "../components/WhatsAppButton";
import AnimationDiv from "../components/AnimatedDiv";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import AnnouncementBar from "../components/AnnouncementBar";
import profileVideoPreview from "../assets/RPReplay_Final1686156119.gif";
import ReviewsSlider from "../components/reviewSlider";

function ProdactPage() {
  const navList = [
    {
      url: "/",
      text: "Accueil",
    },
    {
      url: "/#features",
      text: "Fonctionnalités",
    },
    {
      url: "/#faq",
      text: "FAQ",
    },
  ];

  const { slug } = useParams();
  const product = products.find(
    (p) => p.slug === slug
  );
  const [quantity, setQuantity] = React.useState(1);
  const [hoveredImg, setHoveredImg] = React.useState(null);
  // fbq('track', 'ViewContent', {value: prd.price, currency: 'MAD'});
  fbq("track", "ViewContent", {
    content_ids: [product.id],
    content_type: "product",
    content_name: product.name,
    value: product.price,
    currency: "MAD",
  });
  const navigate = useNavigate();
  const handleMouseOut = () => {
    setHoveredImg(null);
  };
  const handleMouseOver = (index) => {
    setHoveredImg(index);
  };
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AnnouncementBar /> 
      <Navbar navList={navList} showDemo={false} />
      <div className="flex flex-col gap-8 md:gap-2 md:grid md:grid-cols-2 container mx-auto max-w-screen-lg my-20">
        <div>
          <ProductImages images={product.images} />
        </div>
        <div className="flex flex-col gap-6 px-4 md:px-2">
          <ProductHeader
            onChange={(quantity) => {
              setQuantity(quantity);
              console.log("quantity =====>", quantity);
            }}
            pTitle={product.name}
            pPrice={product.price}
            pOldPrice={product.oldPrice}
          />

          <Form id={product.id} quantity={quantity} prd={product} />

          <div className="flex flex-col gap-2 lokap-div">
            {/* <span className="text-3xl font-bold">Description</span> */}
            <div className="ml-4">
              <Description pdtID={parseInt(product.id)} />
            </div>
          </div>
        </div>
      </div>
      <section className="container flex flex-col gap-12 mx-auto my-44 text-center max-w-[1200px]">
        {/* <span className="text-4xl">Nos produits</span> */}
        <div className="flex flex-col md:grid md:grid-cols-3 gap-6 px-4">
          {products.map((singleProduct, index) => {
            if (singleProduct.isCard && singleProduct.id !==  product.id) {
              return (
                <AnimationDiv>
                  <div
                    key={singleProduct.id}
                    className="flex flex-col px-4 h-full py-6 items-center gap-1 rounded-xl bg-gray-100 shadow-md"
                  >
                    <img
                      src={
                        hoveredImg === index
                          ?  singleProduct.imageHover
                          :  singleProduct.gif
                      }
                      alt={singleProduct.name}
                      className="w-[80%] rounded-xl"
                      onMouseOver={() => handleMouseOver(index)}
                      onMouseOut={handleMouseOut}
                    />
                    <div className="text-center px-4">
                      <span className="text-2xl font-extrabold ">
                        Carte {singleProduct.name}
                      </span>
                    </div>
                    <div className="text-center flex gap-3 items-end">
                      <span className="text-lg line-through">
                        {singleProduct.oldPrice} Dh
                      </span>
                      
                      <span className="text-xl font-bold ml-3">
                        {singleProduct.price} Dh
                      </span>
                    </div>
                    <a
                    href={"/product/" + singleProduct.slug}
                      className="w-full max-w-[250px] mt-4"
                      // onClick={() => {
                      //   console.log("hey");
                      //   navigate("/product/" + product.slug);
                      //   // window.open('/product/' + product.slug, '_self');
                      // }}
                    >
                      <Button btnTxt="Voir plus" />
                    </a>
                  </div>
                </AnimationDiv>
              );
            }
          })}
        </div>
      </section>

     

      {/* recycle */}
     
      <AnimatedDiv>
        <FAQ />
      </AnimatedDiv>

      {/* whatsapp */}
      <WhatsAppButton />

      <Footer />
    </>
  );
}

export default ProdactPage;
