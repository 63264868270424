/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewerCount from './ViewerCount';

function ProductHeader({
  pTitle, pPrice, pOldPrice, onChange,
}) {
  const [quantity, setQuantity] = React.useState(1);

  useEffect(() => {
    onChange(quantity);
  }, [quantity, onChange]);

  return (
    <div className="w-full flex flex-col gap-3 px-2 py-3 md:p-3 bg-[#F0F0E9] rounded-lg shadow-sm">
      <span className="text-4xl font-bold capitalize">
        {pTitle}
      </span>
      <div className="flex flex-wrap gap-3 items-center">
        <span className="line-through">

          {' '}
          {pOldPrice}
          .00 Dh
        </span>
        <span className="font-semibold text-lg">
          {pPrice}
          .00 Dh
        </span>
        
        <span className="py-1 px-5 rounded-full text-[#F0F0E9] bg-[#583852]">
          En vent
        </span>
      </div>
      {/* <ViewerCount /> */}
      {/* <div className="mr-10">
        <span>Quanstity :</span>
        <div className="flex w-[150px] mt-2 justify-between bg-white p-2 px-3 rounded-xl border border-gray-400">
          <button
            type="button"
            disabled={quantity === 1}
            onClick={() => setQuantity(quantity - 1)}
            className={`w-10 ${quantity === 1 ? 'opacity-50' : ''}`}
          >
            -
          </button>
          <span>{quantity}</span>
          <button className="w-10" type="button" onClick={() => setQuantity(quantity + 1)}>
            +
          </button>
        </div>
      </div> */}
    </div>
  );
}

ProductHeader.propTypes = {
  pTitle: PropTypes.string.isRequired,
  pPrice: PropTypes.number.isRequired,
  pOldPrice: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProductHeader;
