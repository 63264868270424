const products = [
  // {
  //   id: "1",
  //   isCard: true,
  //   features: [
  //     'Carte de visite intelligente en PVC',
  //     'Profil configurable',
  //     'NFC ou code QR',
  //     'Compte sécurisé'
  //   ],
  //   packs: [
  //     {
  //       id: 1,
  //       title: "x1 Carte à 199Dh",
  //       price: 199,
  //     },
  //     // {
  //     //   id: 2,
  //     //   title: "x2 Cartes à 399Dh",
  //     //   price: 399,
  //     //   isPopular: true,
  //     //   class: "bg-orange-400",
  //     //   message: "Recommandé",
  //     // },
  //     // {
  //     //   id: 3,
  //     //   title: "x10 Cartes à 149dh",
  //     //   price: 149,
  //     //   isPopular: false,
  //     //   class: "bg-purple-800",
  //     //   message: "Entreprise",
  //     // },
  //   ],
  //   slug: "carte-standard",
  //   name: "Standard",
  //   price: "199",
  //   oldPrice: "249",
  //   images: [
  //     "https://api2.lokap.co/uploads/dataFile-1696382873838.png",
  //     "https://api2.lokap.co/uploads/dataFile-1696382929291.png",
  //     "https://api2.lokap.co/uploads/dataFile-1697289430247.jpg",
  //     "https://api2.lokap.co/uploads/dataFile-1697289545655.jpg",
  //     "https://api2.lokap.co/uploads/dataFile-1697289625665.jpg",
  //     "https://api2.lokap.co/uploads/dataFile-1697290060645.jpg",
  //   ],
  //   gif: "https://api2.lokap.co/uploads/dataFile-1696382929291.png",
  //   subDescription: "Carte de visite sans contact NFC en PVC recyclé simple",
  //   description:
  //     "<p>🛜 Utilisation Facile : utilisez le code QR ou la technologie NFC intégrée dans la carte. <br> <br> 📱 Compatible avec la majorité des Smartphones : Lokap fonctionne avec les téléphones Apple et Android. <br><br>  🔐 Sécurité avancée : vos informations sont cryptées et protégées grâce à nos puces NFC de haute qualité. ‍<br><br>  🚚 Livraison en moins de moins de 2 jours</p>",
  // },
  
  {
    id: "1",
    isCard: true,
    features: [
      // 'Carte de visite intelligente en PVC',
      'Profil configurable',
      'Code QR',
      'Mise à jour instantanément',
      'Compte sécurisé'
    ],
    packs: [
      {
        id: 1,
        title: "x1 Carte virtuelle à 49Dh",
        price: 199,
      },
      // {
      //   id: 2,
      //   title: "x2 Cartes à 399Dh",
      //   price: 399,
      //   isPopular: true,
      //   class: "bg-orange-400",
      //   message: "Recommandé",
      // },
      // {
      //   id: 3,
      //   title: "x10 Cartes à 149dh",
      //   price: 149,
      //   isPopular: false,
      //   class: "bg-purple-800",
      //   message: "Entreprise",
      // },
    ],
    slug: "carte-standard",
    name: "Carte de visite virtuelle",
    price: "49",
    oldPrice: "89",
    images: [
      "https://api2.lokap.co/uploads/dataFile-1696382873838.png",
      // "https://api2.lokap.co/uploads/dataFile-1696382929291.png",
      // "https://api2.lokap.co/uploads/dataFile-1697289430247.jpg",
      // "https://api2.lokap.co/uploads/dataFile-1697289545655.jpg",
      // "https://api2.lokap.co/uploads/dataFile-1697289625665.jpg",
      // "https://api2.lokap.co/uploads/dataFile-1697290060645.jpg",
    ],
    gif: "https://api2.lokap.co/uploads/dataFile-1696382929291.png",
    subDescription: "Carte de visite numérique virtuelle que vous pouvez utiliser sans carte physique",
    description:
      `
      <p>
      Découvrez la révolution des cartes de visite virtuelles Lokap !
      <br><br>
      - Créez un profil unique avec photo, coordonnées, compétences, et même vos produits ou portfolios. <br><br>
      - Partagez facilement et simplifiez votre réseau professionnel. <br><br>
      - Modernisez votre présentation professionnelle dès maintenant ! <br><br>
      - Donnez un coup de boost à votre carrière en adoptant notre solution innovante. <br>
      <br><br>
      Effectuez le paiement en toute facilité par virement bancaire<br>
      Optez pour l'innovation dès aujourd'hui !
      <p>
      `
  },
  
  {
    features: [
      'Carte de visite intelligente en PVC',
      'Profil configurable',
      'NFC ou code QR',
      'Ajout du nom complet',
      'Ajout du rôle professionnel',
      'Ajout du logo personnalisé',
      'Compte sécurisé'

    ],

    id: "3",
    isCard: true,
    packs: [
      {
        id: 1,
        title: "x1 Carte à 369Dh",
        price: 369,
      },
      // {
      //   id: 2,
      //   title: "x2 Cartes à 699Dh",
      //   price: 699,
      //   isPopular: true,
      //   class: "bg-orange-400",
      //   message: "Recommandé",
      // },
      // {
      //   id: 3,
      //   title: ">10 Cartes (a négocier)",
      //   price: 999,
      //   isPopular: false,
      //   class: "bg-purple-800",
      //   message: "Entreprise",
      // },
    ],
    slug: "carte-ultra",
    name: "Carte de visite digital",
    price: "369",
    oldPrice: "499",
    images: [
      "https://api2.lokap.co/uploads/dataFile-1698375105481.png",
      "https://api2.lokap.co/uploads/dataFile-1698374889943.png",
      "https://api2.lokap.co/uploads/dataFile-1698374879089.png",
      "https://api2.lokap.co/uploads/dataFile-1698375115867.png",
      "https://api2.lokap.co/uploads/dataFile-1698375138997.png",
      "https://api2.lokap.co/uploads/dataFile-1698375148154.png",
    ],
    gif: "https://api2.lokap.co/uploads/dataFile-1696383107705.png",
    subDescription:
      "Carte de visite sans contact NFC en PVC recyclé avec <b>personnalisation totale</b>",
    description:
      "<p>🎨 Personnalisation totale : créez une carte unique en ajoutant votre logo et en choisissant son design</p><br> <p>🛜 Utilisation Facile : utilisez le code QR ou la technologie NFC intégrée dans la carte. <br> <br> 📱 Compatible avec la majorité des Smartphones : Lokap fonctionne avec les téléphones Apple et Android. <br><br>  🔐 Sécurité avancée : vos informations sont cryptées et protégées grâce à nos puces NFC de haute qualité. ‍<br><br>  🚚 Livraison en moins de moins de 2 jours</p>",
  },
  {
    features: [
      'hello',
    ],
    id: "4",
    isCard: false,
    slug: "carte-d-avis-lokap",
    packs: [
      {
        id: 1,
        title: "x1 Carte à 299Dh",
        price: 249,
      },
      {
        id: 2,
        title: "x2 Cartes à 499Dh",
        price: 499,
        isPopular: true,
        class: "bg-orange-400",
        message: "Recommandé",
      },
      {
        id: 3,
        title: ">10 Cartes (a négocier)",
        price: 999,
        isPopular: false,
        class: "bg-purple-800",
        message: "Entreprise",
      },
    ],
    name: "Carte d'Avis",
    price: "299",
    oldPrice: "499",
    images: [
      "https://api2.lokap.co/uploads/dataFile-1697503242026.png",
      "https://api2.lokap.co/uploads/dataFile-1697503285351.png",
      "https://api2.lokap.co/uploads/dataFile-1697503303130.png",
      "https://api2.lokap.co/uploads/dataFile-1697503326807.png",
      "https://api2.lokap.co/uploads/dataFile-1697503339764.png",
    ],
    gif: "https://api2.lokap.co/uploads/dataFile-1697506048032.png",
    imageHover: "https://api2.lokap.co/uploads/dataFile-1697506220839.png",
    subDescription:
      "Carte de visite sans contact NFC en PVC recyclé avec <b>personnalisation totale</b>",
    description: `
        <p>Découvrez notre nouvelle innovation révolutionnaire: la carte LOKAP NFC générateur d'avis!
        <br>
        <br>
        <p>
        <ul>
        → <b>Augmentez vos avis en ligne </b>
        <br>
        → <b>Facile à utiliser</b>
        <br>

        → <b>Économisez du temps et de l'effort </b>
        <br>

        → <b>Augmentez la confiance des futurs clients</b>
        <br>

        → <b>Boostez votre classement </b>
        <br>

        → <b>Personnalisez votre carte </b>
        <br>
        <br>
       Nous sommes là pour vous aider à toutes les étapes de la mise en place de votre carte NFC.<br><br>

        </ul>
        Obtenez votre Carte NFC Générateur d'Avis dès aujourd'hui et prenez une longueur d'avance sur vos concurrents! 
        <br>
        <br>
        <b style="font-size: 18px; color: red"> Profitez d'une offre spéciale de -50% pour les 500 premiers clients! </b>
        <br>
        <br>
        Ne laissez pas passer cette opportunité unique pour améliorer votre réputation en ligne et développer votre entreprise.
        <br><br>
        <b>Commandez maintenant et offrez à vos clients une expérience de notation simplifiée et pratique!</b>



    
    `,
  },
  {
    id: "5",
    packs: [
      {
        id: 1,
        title: "x1 Tag à 99Dh",
        price: 99,
      },
      {
        id: 2,
        title: "x2 Tags à 149Dh",
        price: 149,
        isPopular: true,
        class: "bg-orange-400",
        message: "Recommandé",
      },
      {
        id: 3,
        title: ">10 Tags à 39Dh",
        price: 39,
        isPopular: false,
        class: "bg-purple-800",
        message: "Entreprise",
      },
    ],
    isCard: false,
    slug: "tag-nfc-lokap",
    name: "Tag NFC Lokap",
    price: "99",
    oldPrice: "149",
    images: ["https://api2.lokap.co/uploads/dataFile-1697508483567.png"],
    gif: "https://api2.lokap.co/uploads/dataFile-1697508608031.png",
    imageHover: "https://api2.lokap.co/uploads/dataFile-1697508608031.png",
    subDescription:
      "Carte de visite sans contact NFC en PVC recyclé avec <b>personnalisation totale</b>",
    description: `
        <p>Découvrez notre dernière innovation incontournable : le NFC Tag de Profil d'Utilisateur !
        <br>
        <br>
        <p>
        <ul>
        → <b>Partage de Profil Facile  </b>
        <br>
        → <b>Simplicité d'Utilisation </b>
        <br>

        → <b>Personnalisation </b>
        <br>

        → <b>Connectivité Rapide </b>
        <br>

        → <b>Sécurité et Confidentialité </b>
        <br>

        → <b>Polyvalence </b>
        <br>
        <br>
       Nous sommes là pour vous aider à toutes les étapes de la mise en place de votre tag NFC.<br><br>

        </ul>
        Obtenez votre Tag NFC dès aujourd'hui et simplifiez la manière dont vous partagez vos informations!
        <br>
        <br>
        <b style="font-size: 18px; color: red"> Profitez d'une offre spéciale de -50% pour les 500 premiers clients! </b>
        <br>
        <br>
        Ne manquez pas cette opportunité pour améliorer votre connectivité et votre efficacité
        <br><br>
        <b>Commandez maintenant et démarrez une nouvelle ère de partage de profil rapide et pratique!</b>

    `,
  },
  {
    id: "6",
    isCard: false,
    slug: "bracelet-nfc-lokap",
    name: "Bracelet NFC Lokap",
    price: "149",
    packs: [
      {
        id: 1,
        title: "x1 Bracelet à 149Dh",
        price: 149,
      },
      {
        id: 2,
        title: "x2 Bracelets à 249Dh",
        price: 249,
        isPopular: true,
        class: "bg-orange-400",
        message: "Recommandé",
      },
      {
        id: 3,
        title: ">10 Bracelet à 69Dh",
        price: 69,
        isPopular: false,
        class: "bg-purple-800",
        message: "Entreprise",
      },
    ],
    oldPrice: "299",
    images: ["https://api2.lokap.co/uploads/dataFile-1697508719497.png"],
    gif: "https://api2.lokap.co/uploads/dataFile-1697508760314.png",
    imageHover: "https://api2.lokap.co/uploads/dataFile-1697508760314.png",
    subDescription:
      "Carte de visite sans contact NFC en PVC recyclé avec <b>personnalisation totale</b>",
    description: `
      <p>Découvrez notre dernière innovation incontournable : le NFC Bracelet de Profil d'Utilisateur !
      <br>
      <br>
      <p>
      <ul>
      → <b>Partage de Profil Facile  </b>
      <br>
      → <b>Simplicité d'Utilisation </b>
      <br>

      → <b>Personnalisation </b>
      <br>

      → <b>Connectivité Rapide </b>
      <br>

      → <b>Sécurité et Confidentialité </b>
      <br>

      → <b>Polyvalence </b>
      <br>
      <br>
     Nous sommes là pour vous aider à toutes les étapes de la mise en place de votre Bracelet NFC.<br><br>

      </ul>
      Obtenez votre Bracelet NFC dès aujourd'hui et simplifiez la manière dont vous partagez vos informations!
      <br>
      <br>
      <b style="font-size: 18px; color: red"> Profitez d'une offre spéciale de -50% pour les 500 premiers clients! </b>
      <br>
      <br>
      Ne manquez pas cette opportunité pour améliorer votre connectivité et votre efficacité
      <br><br>
      <b>Commandez maintenant et démarrez une nouvelle ère de partage de profil rapide et pratique!</b>

  `,
  },
];

export default products;
