import React from 'react';
import PropTypes from 'prop-types';

function ColoredTxt({ text }) {
  return (
    <span className="gradient-text text-4xl font-black">{text}</span>
  );
}

ColoredTxt.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ColoredTxt;
